<template>
  <div>
    <b-row>
      <b-col md="4">
        <ShipmentAddress
          :quote="quote"
          :show-toggle-button="true"
          :show-use-other-button="false"
        />
      </b-col>
      <b-col md="4">
        <InvoiceAddress
          :quote="quote"
          :show-toggle-button="true"
          :show-use-other-button="false"
        />
      </b-col>
      <b-col md="4">
        <b-card class="border border-dark shadow-none">
          <div>
            <b-row>
              <b-col class="align-self-end">
                <h4 class="font-weight-bolder">
                  Datos de facturación
                </h4>
              </b-col>
              <b-button
                :variant="visible ? 'flat-primary' : 'flat-secondary'"
                class="btn-icon rounded-circle mr-1"
                @click="collapse"
              >
                <feather-icon :icon="iconToggle" />
              </b-button>

            </b-row>
          </div>

          <b-collapse
            :id="collapseItemId"
            v-model="visible"
            role="tabpanel"
          >
            <InformationBasicCard
              v-if="customerInfo.BusinessName"
              title="Razón social"
              :tags="[{ value:customerInfo.BusinessName, type: 'string' }]"
            />
            <InformationBasicCard
              title="Uso de CFDI"
              :tags="[{ value:customerInfo.Cfdi, type: 'string' }]"
            />
            <InformationBasicCard
              title="RFC"
              :tags="[{ value:customerInfo.Rfc, type: 'string' }]"
            />
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BCollapse,
} from 'bootstrap-vue'

import InformationBasicCard from '@/components/cards/InformationBasicCard.vue'
import InvoiceAddress from '@/modules/users/wholesalers/components/InvoiceAddress.vue'
import ShipmentAddress from '@/modules/users/wholesalers/components/ShipmentAddress.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCollapse,
    InvoiceAddress,
    ShipmentAddress,
    InformationBasicCard,
  },
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemId: '5',
      iconToggle: 'ChevronDownIcon',
      customerInfo: null,
    }
  },
  created() {
    const [customerInfo] = this.quote.user
    this.customerInfo = customerInfo
  },
  methods: {
    collapse() {
      this.$root.$emit('bv::toggle::collapse', this.collapseItemId)
      this.visible = !this.visible
      this.setToggleIcon()
    },
    setToggleIcon() {
      this.iconToggle = this.visible ? 'ChevronUpIcon' : 'ChevronDownIcon'
    },
  },
}
</script>
