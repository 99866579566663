<template>
  <b-row class="justify-content-center">

    <!-- #region begin::Layout for column definitions -->
    <b-col
      xl="9"
      lg="10"
      md="12"
    >
      <validation-observer ref="wholesalerForm">

        <!-- #region::Wholesalers Form -->
        <b-form @submit="$event.preventDefault()">

          <!-- #region::Card for main options -->
          <div class="mb-2">
            <MainOptions
              :title="id ? 'Actualizar mayorista' : 'Nuevo mayorista'"
              :save-button-text="id ? 'Guardar cambios' : 'Guardar mayorista'"
              model-home-route="wholesalers-home"
              :callback="saveWholesaler"
            />
          </div>
          <!-- #endregion::Card for main options -->

          <!-- #region::Skeleton -->
          <template v-if="loadingDataForm">
            <BasicSkeleton height="350px" />
            <BasicSkeleton height="350px" />
            <BasicSkeleton height="350px" />
          </template>
          <!-- #endregion::Skeleton -->

          <!-- #region::Fields form -->
          <template v-else>

            <!-- #region::Wholesaler information -->
            <b-card>
              <b-card-text>
                <b-row>
                  <b-col md="12">
                    <h4>Información del mayorista</h4>
                  </b-col>

                  <!-- #region::Name input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="name"
                      vid="name"
                      rules="required|min:2"
                      type="text"
                      label="Nombre del mayorista"
                      name="nombre"
                      maxlength="200"
                      placeholder="Escribe el nombre del mayorista"
                    />
                  </b-col>
                  <!-- #endregion::Name input -->

                  <!-- #region::Name input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="enterprise"
                      vid="enterprise"
                      rules="required|min:2"
                      type="text"
                      label="Nombre de la empresa"
                      name="empresa"
                      maxlength="200"
                      placeholder="Escribe el nombre de la empresa"
                    />
                  </b-col>
                  <!-- #endregion::Name input -->

                  <!-- #region::Email input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="email"
                      vid="email"
                      rules="required|email_prefix"
                      type="text"
                      label="Correo electrónico"
                      name="correo electrónico"
                      append="@optimumequipo.com"
                      placeholder="Escribe el correo electrónico"
                      :disabled="passwordChanged"
                      :append-disabled="true"
                    />
                  </b-col>
                  <!-- #endregion::Email input -->

                  <!-- #region::Phone input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="phone"
                      vid="phone"
                      rules="required|digits:10"
                      type="text"
                      label="Teléfono"
                      name="teléfono"
                      maxlength="10"
                      placeholder="Escribe el número telefónico"
                    />
                  </b-col>
                  <!-- #endregion::Phone input -->

                  <!-- #region::Discount input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="discount"
                      vid="discount"
                      rules="decimal"
                      type="text"
                      label="Descuento"
                      name="descuento"
                      maxlength="12"
                      append="%"
                      placeholder="25.99"
                    />
                  </b-col>
                  <!-- #endregion::Discount input -->

                  <!-- #region::Password input -->
                  <b-col
                    v-if="id"
                    md="6"
                    class="mt-1"
                  >
                    <!-- password -->
                    <b-form-group
                      label="Contraseña"
                      label-size="sm"
                      label-for="reset-password-new"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="contraseña"
                        vid="Password"
                        rules="password"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            id="reset-password-new"
                            v-model="password"
                            :type="password1FieldType"
                            :state="errors.length > 0 ? false:null"
                            class="form-control-merge"
                            name="reset-password-new"
                            placeholder="············"
                            :disabled="emailChanged"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="password1ToggleIcon"
                              @click="togglePassword1Visibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- #endregion::Password input -->
                </b-row>
              </b-card-text>
            </b-card>
            <!-- #endregion::Wholesaler information -->

            <!-- #region::Shipment information -->
            <b-card>
              <b-card-text>
                <b-row>
                  <b-col md="12">
                    <h4>Dirección de envío</h4>
                  </b-col>

                  <!-- #region::Shipment contact name input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="shipmentContact"
                      vid="shipmentContact"
                      rules="min:2"
                      type="text"
                      label="Nombre del contacto"
                      name="contacto"
                      maxlength="200"
                      placeholder="Escribe el nombre del contacto"
                    />
                  </b-col>
                  <!-- #endregion::Shipment contact name input -->

                  <!-- #region::Shipment address input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="shipmentAddress"
                      vid="shipmentAddress"
                      rules="min:2"
                      type="text"
                      label="Dirección"
                      name="dirección"
                      maxlength="250"
                      placeholder="Escribe la dirección"
                    />
                  </b-col>
                  <!-- #endregion::Shipment address input -->

                  <!-- #region::Shipment zip code input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="shipmentZipCode"
                      vid="shipmentZipCode"
                      rules="digits:5"
                      type="text"
                      label="Código postal"
                      name="código postal"
                      maxlength="5"
                      placeholder="Escribe el código postal"
                      @input="handleShipmentZipCode"
                    />
                  </b-col>
                  <!-- #endregion::Shipment zip code input -->

                  <!-- #region::Shipment colony select -->
                  <b-col md="6">
                    <SelectWithValidation
                      v-model="shipmentColony"
                      vid="shipmentColony"
                      name="colonia"
                      label="Colonia"
                      property="name"
                      placeholder="Selecciona la colonia"
                      :options="shipmentColonies"
                      :disabled="shipmentColonies.length === 0"
                    />
                  </b-col>
                  <!-- #endregion::Shipment colony select -->

                  <!-- #region::Shipment city select -->
                  <b-col md="6">
                    <SelectWithValidation
                      v-model="shipmentCity"
                      vid="shipmentCity"
                      name="ciudad"
                      label="Ciudad"
                      property="name"
                      placeholder="Selecciona la ciudad"
                      :options="shipmentCities"
                      :disabled="shipmentCities.length === 0"
                    />
                  </b-col>
                  <!-- #endregion::Shipment city select -->

                  <!-- #region::Shipment state select -->
                  <b-col md="6">
                    <SelectWithValidation
                      v-model="shipmentState"
                      vid="shipmentState"
                      name="estado"
                      label="Estado"
                      property="name"
                      placeholder="Selecciona la estado"
                      :options="shipmentStates"
                      :disabled="shipmentStates.length === 0"
                    />
                  </b-col>
                  <!-- #endregion::Shipment state select -->

                  <!-- #region::Shipment email input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="shipmentEmail"
                      vid="shipmentEmail"
                      rules="email"
                      type="email"
                      label="Correo electrónico"
                      name="correo electrónico"
                      placeholder="Escribe el correo electrónico"
                    />
                  </b-col>
                  <!-- #endregion::Shipment email input -->

                  <!-- #region::Shipment phone input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="shipmentPhone"
                      vid="shipmentPhone"
                      rules="digits:10"
                      type="text"
                      label="Teléfono"
                      name="teléfono"
                      maxlength="10"
                      placeholder="Escribe el número telefónico"
                    />
                  </b-col>
                  <!-- #endregion::Shipment phone input -->

                </b-row>
              </b-card-text>
            </b-card>
            <!-- #endregion::Shipment information -->

            <!-- #region::Invoice address -->
            <b-card>
              <b-card-text>
                <b-row>
                  <b-col md="6">
                    <h4>Dirección de facturación</h4>
                  </b-col>

                  <!-- #region::Invoice same address checkbox -->
                  <b-col
                    md="6"
                    class="d-flex"
                  >
                    <b-form-checkbox
                      v-model="sameInvoiceAddress"
                      class="ml-auto"
                    >
                      <strong>Usar dirección de envío</strong>
                    </b-form-checkbox>
                  </b-col>
                  <!-- #endregion::Invoice same address checkbox -->

                  <!-- #region::Invoice contact name input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="invoiceContact"
                      vid="invoiceContact"
                      rules="min:2"
                      type="text"
                      label="Nombre del contacto"
                      name="contacto"
                      maxlength="200"
                      placeholder="Escribe el nombre del contacto"
                      :disabled="sameInvoiceAddress"
                    />
                  </b-col>
                  <!-- #endregion::Invoice contact name input -->

                  <!-- #region::Invoice address input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="invoiceAddress"
                      vid="invoiceAddress"
                      rules="min:2"
                      type="text"
                      label="Dirección"
                      name="dirección"
                      maxlength="250"
                      placeholder="Escribe la dirección"
                      :disabled="sameInvoiceAddress"
                    />
                  </b-col>
                  <!-- #endregion::Invoice address input -->

                  <!-- #region::Invoice zip code input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="invoiceZipCode"
                      vid="invoiceZipCode"
                      rules="digits:5"
                      type="text"
                      label="Código postal"
                      name="código postal"
                      maxlength="5"
                      placeholder="Escribe el código postal"
                      :disabled="sameInvoiceAddress"
                      @input="handleInvoiceZipCode"
                    />
                  </b-col>
                  <!-- #endregion::Invoice zip code input -->

                  <!-- #region::Invoice colony select -->
                  <b-col md="6">
                    <SelectWithValidation
                      v-model="invoiceColony"
                      vid="invoiceColony"
                      name="colonia"
                      label="Colonia"
                      property="name"
                      placeholder="Selecciona la colonia"
                      :options="invoiceColonies"
                      :disabled="invoiceColonies.length === 0 || sameInvoiceAddress"
                    />
                  </b-col>
                  <!-- #endregion::Invoice colony select -->

                  <!-- #region::Invoice city select -->
                  <b-col md="6">
                    <SelectWithValidation
                      v-model="invoiceCity"
                      vid="invoiceCity"
                      name="ciudad"
                      label="Ciudad"
                      property="name"
                      placeholder="Selecciona la ciudad"
                      :options="invoiceCities"
                      :disabled="invoiceCities.length === 0 || sameInvoiceAddress"
                    />
                  </b-col>
                  <!-- #endregion::Invoice city select -->

                  <!-- #region::Invoice state select -->
                  <b-col md="6">
                    <SelectWithValidation
                      v-model="invoiceState"
                      vid="invoiceState"
                      name="estado"
                      label="Estado"
                      property="name"
                      placeholder="Selecciona la estado"
                      :options="invoiceStates"
                      :disabled="invoiceStates.length === 0 || sameInvoiceAddress"
                    />
                  </b-col>
                  <!-- #endregion::Invoice state select -->

                  <!-- #region::Invoice email input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="invoiceEmail"
                      vid="invoiceEmail"
                      rules="email"
                      type="email"
                      label="Correo electrónico"
                      name="correo electrónico"
                      placeholder="Escribe el correo electrónico"
                      :disabled="sameInvoiceAddress"
                    />
                  </b-col>
                  <!-- #endregion::Invoice email input -->

                  <!-- #region::Invoice phone input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="invoicePhone"
                      vid="invoicePhone"
                      rules="digits:10"
                      type="text"
                      label="Teléfono"
                      name="teléfono"
                      maxlength="10"
                      placeholder="Escribe el número telefónico"
                      :disabled="sameInvoiceAddress"
                    />
                  </b-col>
                  <!-- #endregion::Invoice phone input -->

                </b-row>
              </b-card-text>
            </b-card>
            <!-- #endregion::Invoice address -->

            <!-- #region::Invoice information -->
            <b-card>
              <b-card-text>
                <b-row>
                  <b-col md="12">
                    <h4>Datos de facturación</h4>
                  </b-col>

                  <!-- #region::Business name input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="businessName"
                      vid="businessName"
                      rules="min:2"
                      type="text"
                      label="Razón social"
                      name="razón social"
                      maxlength="200"
                      placeholder="Escribe la razón social"
                    />
                  </b-col>
                  <!-- #endregion::Business name input -->

                  <!-- #region::Business name input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="CFDIUssage"
                      vid="CFDIUssage"
                      rules="min:2"
                      type="text"
                      label="Uso de CFDI"
                      name="uso de CFDI"
                      maxlength="200"
                      placeholder="Escribe el uso de CFDI"
                    />
                  </b-col>
                  <!-- #endregion::Business name input -->

                  <!-- #region::Business name input -->
                  <b-col md="6">
                    <TextInputWithValidation
                      v-model="RFC"
                      vid="RFC"
                      rules="min:2"
                      type="text"
                      label="RFC"
                      name="RFC"
                      maxlength="200"
                      placeholder="Escribe el RFC"
                    />
                  </b-col>
                  <!-- #endregion::Business name input -->

                </b-row>
              </b-card-text>
            </b-card>
            <!-- #endregion::Invoice information -->

          </template>
          <!-- #endregion::Fields form -->

        </b-form>
      <!-- #endregion::Wholesalers Form -->

      </validation-observer>
    </b-col>
  </b-row>
</template>

<script>
// #region imports
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import {
  required, min, email as emailRule, digits, numeric, decimal, emailPrefix,
} from '@validations'
import {
  BForm, BRow, BCol, BCard, BCardText, BFormCheckbox, BFormGroup,
  BInputGroup, BFormInput, BInputGroupAppend,
} from 'bootstrap-vue'

import MainOptions from '@/components/forms/MainOptions.vue'
import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import SelectWithValidation from '@/components/forms/SelectWithValidation.vue'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BCardText,
    BFormInput,
    BFormGroup,
    BInputGroup,
    MainOptions,
    BFormCheckbox,
    BasicSkeleton,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    SelectWithValidation,
    TextInputWithValidation,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      // * 17/10/2022 - TAG: Wholesaler data
      name: '',
      email: '',
      phone: '',
      discount: '',
      enterprise: '',
      password: '',
      user: null,

      // * 17/10/2022 - TAG: Shipment info
      shipmentId: '',
      shipmentCity: '',
      shipmentState: '',
      shipmentEmail: '',
      shipmentPhone: '',
      shipmentColony: '',
      shipmentContact: '',
      shipmentAddress: '',
      shipmentZipCode: '',

      // * 17/10/2022 - TAG: Invoice info
      invoice: null,
      invoiceId: '',
      invoiceCity: '',
      invoiceState: '',
      invoiceEmail: '',
      invoicePhone: '',
      invoiceColony: '',
      invoiceContact: '',
      invoiceAddress: '',
      invoiceZipCode: '',
      sameInvoiceAddress: false,

      // * 17/10/2022 - TAG: Invoice data
      RFC: '',
      CFDIUssage: '',
      businessName: '',

      // * 17/10/2022 - TAG: Validations
      min,
      digits,
      numeric,
      decimal,
      required,
      emailRule,
      emailPrefix,

      // * Toggle Password
      password1FieldType: 'password',

      loadingDataForm: true,
      shipmentCities: [],
      shipmentStates: [],
      shipmentColonies: [],

      invoiceCities: [],
      invoiceStates: [],
      invoiceColonies: [],
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    emailChanged() {
      if (!this.user) {
        return false
      }
      return this.email !== this.user.email.substring(0, this.user.email.indexOf('@'))
    },
    passwordChanged() {
      if (!this.user) {
        return false
      }
      return this.password.length > 0
    },
  },
  watch: {
    sameInvoiceAddress(isSameInvoiceAddress) {
      if (isSameInvoiceAddress) {
        if (this.$route.name === 'new-wholesaler') {
          this.fillFromLocalShipment()
        }

        if (this.$route.name === 'update-wholesaler') {
          if (this.invoice) {
            this.fillFromInvoice()
          } else {
            this.fillFromLocalShipment()
          }
        }
      }
    },
  },
  async created() {
    this.loadingDataForm = true
    this.$swal({
      title: 'Espere por favor',
      allowOutsideClick: false,
    })

    this.$swal.showLoading()

    this.loadingDataForm = true

    if (this.id) {
      const response = await this.loadWholesaler(this.id)
      const user = response.data.data.user[0]
      this.user = user
      this.loadExistingUser(user)

      if (response.data.data.address.length > 0) {
        await this.loadExistingShipment(response.data.data.address[0])
      }

      if (response.data.data.billings.length > 0) {
        await this.loadExistingInvoice(response.data.data.billings[0])
      }
    }

    localize('es')
    this.loadingDataForm = false
    this.$swal.close()
  },
  methods: {
    ...mapActions({
      loadZipCode: 'wholesalers/loadZipCode',
      loadWholesaler: 'wholesalers/loadWholesaler',
      createWholesaler: 'wholesalers/createWholesaler',
      updateWholesaler: 'wholesalers/updateWholesaler',
    }),
    async saveWholesaler() {
      const success = await this.$refs.wholesalerForm.validate()

      if (success) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        const formData = new FormData()

        if (this.id) {
          formData.append('id', this.id)
          formData.append('idAddress', this.shipmentId)
          formData.append('idBilling', this.invoiceId)

          if (this.passwordChanged) {
            formData.append('password', this.password)
          }
        }

        // 19/10/2022 - TAG: Wholesaler information
        formData.append('name', this.name)
        formData.append('email', `${this.email}@optimumequipo.com`)
        formData.append('phone', this.phone)
        formData.append('discount', this.discount)
        formData.append('company', this.enterprise)

        // 19/10/2022 - TAG: Shipment information
        formData.append('cityAddress', this.shipmentCity)
        formData.append('stateAddress', this.shipmentState)
        formData.append('emailAddress', this.shipmentEmail)
        formData.append('phoneAddress', this.shipmentPhone)
        formData.append('addressAddress', this.shipmentAddress)
        formData.append('postalCodeAddress', this.shipmentZipCode)
        formData.append('contactNameAddress', this.shipmentContact)
        formData.append('neighborhoodAddress', this.shipmentColony)

        // 19/10/2022 - TAG: Invoice address
        formData.append('cityBilling', this.invoiceCity)
        formData.append('stateBilling', this.invoiceState)
        formData.append('emailBilling', this.invoiceEmail)
        formData.append('phoneBilling', this.invoicePhone)
        formData.append('addressBilling', this.invoiceAddress)
        formData.append('postalCodeBilling', this.invoiceZipCode)
        formData.append('contactNameBilling', this.invoiceContact)
        formData.append('neighborhoodBilling', this.invoiceColony)
        formData.append('iqualAddressBilling', this.sameInvoiceAddress ? 1 : 0)

        // 19/10/2022 - TAG: Invoice information
        formData.append('rfc', this.RFC)
        formData.append('cfdi', this.CFDIUssage)
        formData.append('businessName', this.businessName)

        // for (const pair of formData.entries()) {
        //   console.log(`${pair[0]}, ${pair[1]}`)
        // }

        try {
          if (this.id) {
            await this.updateWholesaler(formData)
            this.showSwalFire('¡El mayorista ha sido actualizado correctamente!')
          } else {
            await this.createWholesaler(formData)
            this.showSwalFire('¡El mayorista ha sido registrado correctamente!')
          }
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },
    async handleShipmentZipCode() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      if (this.shipmentZipCode.toString().length === 5) {
        this.timeout = setTimeout(async () => {
          const zipCodeInfo = await this.loadZipCode(this.shipmentZipCode)
          const { data } = zipCodeInfo.data

          data.forEach(element => {
            this.shipmentColonies.unshift(element.Location)

            if (this.shipmentCities.indexOf(element.Town) === -1) {
              this.shipmentCities.unshift(element.Town)
            }

            if (this.shipmentStates.indexOf(element.State) === -1) {
              this.shipmentStates.unshift(element.State)
            }
          })
        }, 500)
      } else {
        this.shipmentCities = []
        this.shipmentStates = []
        this.shipmentColonies = []
        this.shipmentCity = ''
        this.shipmentState = ''
        this.shipmentColony = ''
      }
    },
    async handleInvoiceZipCode() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      if (this.invoiceZipCode.toString().length === 5) {
        this.timeout = setTimeout(async () => {
          const zipCodeInfo = await this.loadZipCode(this.invoiceZipCode)
          const { data } = zipCodeInfo.data

          data.forEach(element => {
            this.invoiceColonies.unshift(element.Location)

            if (this.invoiceCities.indexOf(element.Town) === -1) {
              this.invoiceCities.unshift(element.Town)
            }

            if (this.invoiceStates.indexOf(element.State) === -1) {
              this.invoiceStates.unshift(element.State)
            }
          })
        }, 500)
      } else {
        this.invoiceCities = []
        this.invoiceStates = []
        this.invoiceColonies = []
        this.invoiceCity = ''
        this.invoiceState = ''
        this.invoiceColony = ''
      }
    },
    loadExistingUser(user) {
      this.name = user.Name
      this.email = user.email.substring(0, user.email.indexOf('@'))
      this.phone = user.Phone
      this.enterprise = user.Company
      this.discount = user.Discount || ''

      this.RFC = user.Rfc || ''
      this.CFDIUssage = user.Cfdi || ''
      this.businessName = user.BusinessName || ''
    },
    async loadExistingShipment(shipment) {
      this.shipmentId = shipment.IdAddress || ''
      this.shipmentCity = shipment.City || ''
      this.shipmentState = shipment.State || ''
      this.shipmentEmail = shipment.Email || ''
      this.shipmentPhone = shipment.Phone || ''
      this.shipmentColony = shipment.Neighborhood || ''
      this.shipmentContact = shipment.ContactName || ''
      this.shipmentAddress = shipment.Address || ''
      this.shipmentZipCode = shipment.PostalCode || ''
    },
    async loadExistingInvoice(invoice) {
      this.invoice = invoice
      this.invoiceId = invoice.IdBillingdata || ''
      this.invoiceCity = invoice.City || ''
      this.invoiceState = invoice.State || ''
      this.invoiceEmail = invoice.Email || ''
      this.invoicePhone = invoice.Phone || ''
      this.invoiceColony = invoice.Neighborhood || ''
      this.invoiceContact = invoice.ContactName || ''
      this.invoiceAddress = invoice.Address || ''
      this.invoiceZipCode = invoice.PostalCode || ''

      this.sameInvoiceAddress = invoice.IqualAddress === 1 || ''
    },
    fillFromLocalShipment() {
      this.invoiceCity = this.shipmentCity || ''
      this.invoiceState = this.shipmentState || ''
      this.invoiceEmail = this.shipmentEmail || ''
      this.invoicePhone = this.shipmentPhone || ''
      this.invoiceColony = this.shipmentColony || ''
      this.invoiceContact = this.shipmentContact || ''
      this.invoiceAddress = this.shipmentAddress || ''
      this.invoiceZipCode = this.shipmentZipCode || ''
    },
    fillFromInvoice() {
      this.invoiceCity = this.invoice.City || ''
      this.invoiceState = this.invoice.State || ''
      this.invoiceEmail = this.invoice.Email || ''
      this.invoicePhone = this.invoice.Phone || ''
      this.invoiceColony = this.invoice.Neighborhood || ''
      this.invoiceContact = this.invoice.ContactName || ''
      this.invoiceAddress = this.invoice.Address || ''
      this.invoiceZipCode = this.invoice.PostalCode || ''
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    showSwalFire(message) {
      this.$swal.fire({
        title: 'Guardado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'wholesalers-home' })
        }
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
