<template>
  <div>
    <b-row class="justify-content-center">

      <!-- #region begin::Layout for column definitions -->
      <b-col
        md="12"
      >
        <!-- #region::Wholesalers Form -->
        <b-form @submit="$event.preventDefault()">
          <b-col
            cols="12"
            class="px-0 mb-1"
          >
            <small>
              <router-link
                :to="{ name: 'wholesalers-home' }"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  class="mr-50 text-secondary"
                />
                <span class="align-middle text-secondary">Regresar</span>
              </router-link>
            </small>
          </b-col>
          <!-- #region::Card for main options -->
          <div class="mb-2">
            <DetailOptions
              :title="'Detalles del mayorista'"
              :edit-button-text="'Editar'"
              :delete-button-text="'Eliminar'"
              :user="'Wholesaler'"
              :show-edit-button="canManageWholesalerUsers"
              :show-delete-button="canManageWholesalerUsers"
              @on:edit="isSidebarOpen = true"
              @on:delete="onDeleteSeller(user)"
            />
          </div>
          <!-- #endregion::Card for main options -->

          <!-- #region::Skeleton -->
          <template v-if="loadingDataSeller">
            <BasicSkeleton height="350px" />
            <BasicSkeleton height="350px" />
            <BasicSkeleton height="350px" />
          </template>
          <!-- #endregion::Skeleton -->

          <!-- #region::Fields form -->
          <template v-else>

            <metrics
              :title="'Información del mayorista'"
              :items="sellerData"
            />
            <WholesalersData :quote="dataUser" />
            <div class="mb-2">
              <b-form-row>
                <b-col class="align-self-center">
                  <h3>Estadísticas</h3>
                </b-col>
              </b-form-row>
            </div>
            <hr>
            <b-row class="match-height">
              <b-col md="4">
                <EcommerceMedal :data="totalSales">
                  <template slot="datePicker">
                    <DashboardFilters
                      class="ml-1"
                      style="width: auto"
                      filter-type="totalSale"
                    />
                  </template>
                </EcommerceMedal>
              </b-col>
              <b-col md="4">
                <EcommerceMedal :data="totalPayments">
                  <template slot="datePicker">
                    <DashboardFilters
                      class="ml-1"
                      style="width: auto"
                      filter-type="registeredPayments"
                    />
                  </template>
                </EcommerceMedal>
              </b-col>
            </b-row>
            <metrics
              :title="'Cotizaciones'"
              :items="quotesData"
            />
            <metrics
              :title="'Órdenes'"
              :items="ordersData"
              :grid="'ordenes'"
            />
            <div class="mb-2">
              <b-form-row>
                <b-col class="align-self-center">
                  <h3>Comercial</h3>
                </b-col>
              </b-form-row>
            </div>
            <hr>
            <b-tabs>
              <b-tab
                title="Cotizaciones"
                active
                lazy
              >
                <QuotesList
                  :user-detail="true"
                  :type-user="'mayorista'"
                  :id-user-external="idUser"
                  :can-apply-global-filters="false"
                  :can-apply-global-pagination="false"
                />
              </b-tab>
              <b-tab
                title="Órdenes"
                lazy
              >
                <OrdersList
                  :user-detail="true"
                  :type-user="'mayorista'"
                  :id-user-external="idUser"
                  :can-apply-global-filters="false"
                  :can-apply-global-pagination="false"
                />
              </b-tab>
            </b-tabs>
          </template>
          <!-- #endregion::Fields form -->
        </b-form>
        <!-- #endregion::Wholesalers Form -->
      </b-col>
    </b-row>
    <b-sidebar
      id="sidebar-right"
      v-model="isSidebarOpen"
      bg-variant="white"
      width="30em"
      :no-close-on-backdrop="true"
      right
      backdrop
      no-header
      shadow
    >
      <SaveWholesalerForm
        :id="idUser"
        ref="saveSellerForm"
        @on:close="isSidebarOpen = false"
        @on:reload="loadUserSeller"
      />
    </b-sidebar>
  </div>
</template>

<script>
// #region imports
import { mapActions, mapGetters } from 'vuex'
import {
  BForm, BRow, BCol, BFormRow, BTabs, BTab, BSidebar,
} from 'bootstrap-vue'

import Metrics from '@/components/cards/Metrics.vue'
import DetailOptions from '@/components/forms/DetailOptions.vue'
import BasicSkeleton from '@/components/cards/BasicSkeleton.vue'
import EcommerceMedal from '@/components/cards/EcommerceMedal.vue'
import OrdersList from '@/modules/trade/orders/views/OrdersList.vue'
import QuotesList from '@/modules/trade/quotes/views/QuotesList.vue'
import WholesalersData from '@/modules/users/wholesalers/components/WholesalersData.vue'
import SaveWholesalerForm from '@/modules/users/wholesalers/views/SaveWholesalerForm.vue'

import getError from '@/helpers/ErrorsHandler'
import DashboardFilters from '@/modules/home/dashboard/components/DashboardFilters.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { adminLevel, ADMIN_USER_LEVELS } from '@/helpers/UserLevel'

// #endregion

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BForm,
    BTabs,
    Metrics,
    BFormRow,
    BSidebar,
    OrdersList,
    QuotesList,
    DetailOptions,
    BasicSkeleton,
    EcommerceMedal,
    WholesalersData,
    DashboardFilters,
    SaveWholesalerForm,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      loadingDataSeller: true,
      user: null,
      selectedMonth: '',
      sellerData: [],
      quotesData: [],
      ordersData: [],
      dataUser: [],
      isSidebarOpen: false,
      idUser: null,
      totalSales: {
        title: 'Total de venta',
        amount: 0,
        image: true,
      },
      totalPayments: {
        title: 'Total de pagos registrados',
        amount: 0,
        image: false,
      },
      adminLevel,
      ADMIN_USER_LEVELS,
    }
  },
  computed: {
    ...mapGetters({
      getTotalSaleRangeDates: 'dashboard/getTotalSaleRangeDates',
      getRegisteredPaymentsRangeDates: 'dashboard/getRegisteredPaymentsRangeDates',
    }),
    /**
     * Rango de fechas del total de venta
     */
    totalSaleRangeDates: {
      get() { return this.getTotalSaleRangeDates },
      set(value) { this.setTotalSaleRangeDates(value) },
    },
    /**
     * Rango de fechas de los pagos registrados
     */
    registeredPaymentsRangeDates: {
      get() { return this.getRegisteredPaymentsRangeDates },
      set(value) { this.setRegisteredPaymentsRangeDates(value) },
    },
    /**
     * Indica si el usuario puede gestionar usuarios mayoristas
     */
    canManageWholesalerUsers() {
      return this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
  },
  watch: {
    totalSaleRangeDates(newTotalSaleRangeDates) {
      this.loadTotalSale(newTotalSaleRangeDates)
    },
    registeredPaymentsRangeDates(newRegisteredPaymentsRangeDates) {
      this.loadTotalRegisteredPayments(newRegisteredPaymentsRangeDates)
    },
  },
  async mounted() {
    await this.loadUserSeller()
  },
  async created() {
    this.idUser = String(this.$route.params?.id)
  },
  methods: {
    ...mapActions({
      loadSeller: 'wholesalers/loadWholesaler',
      deleteSeller: 'wholesalers/deleteWholesaler',
      loadTotalSaleByUser: 'dashboard/loadTotalSaleByUser',
      loadTotalRegisteredPaymentsByUser: 'dashboard/loadTotalRegisteredPaymentsByUser',
    }),
    async loadUserSeller() {
      this.loadingDataSeller = true
      this.$swal({
        title: 'Espere por favor',
        allowOutsideClick: false,
      })

      this.$swal.showLoading()

      this.loadingDataSeller = true

      if (this.id) {
        const response = await this.loadSeller(this.id)
        console.log('response', response)
        const user = response.data.data.user[0]
        this.user = user
        // eslint-disable-next-line no-unused-expressions
        this.totalSales.amount = user.total_sales

        await this.loadTotalRegisteredPayments(null)

        this.dataUser = response.data.data
        this.sellerData = [
          {
            icon: 'UserIcon',
            color: 'light-info',
            title: 'Nombre',
            subtitle: user.Name,
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'TrelloIcon',
            color: 'light-primary-second',
            title: 'Empresa',
            subtitle: user.Company,
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'MailIcon',
            color: 'light-primary',
            title: 'Correo',
            subtitle: user.email,
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'PhoneIcon',
            color: 'light-success',
            title: 'Teléfono',
            subtitle: user.Phone,
            customClass: '',
          },
          {
            icon: 'PercentIcon',
            color: 'light-danger',
            title: 'Porcentaje de descuento',
            subtitle: `${user.Discount}%`,
            customClass: '',
          },
        ]
        this.quotesData = [
          {
            icon: 'BoxIcon',
            color: 'light-secondary',
            title: user.quote_total,
            subtitle: 'Totales',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'FileTextIcon',
            color: 'light-success',
            title: user.quote_in_quote,
            subtitle: 'En cotización',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'SendIcon',
            color: 'light-info',
            title: user.quote_sent,
            subtitle: 'Enviadas',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'ClockIcon',
            color: 'light-danger',
            title: user.quote_on_request,
            subtitle: 'En solicitud',
            customClass: '',
          },
          {
            icon: 'XIcon',
            color: 'light-danger',
            title: user.quote_denied,
            subtitle: 'Rechazadas',
            customClass: '',
          },
          {
            icon: 'SlashIcon',
            color: 'light-secondary',
            title: user.quote_cancelled,
            subtitle: 'Canceladas',
            customClass: '',
          },
        ]
        this.ordersData = [
          {
            icon: 'BookOpenIcon',
            color: 'light-success',
            title: user.order_total,
            subtitle: 'Órdenes',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'CheckIcon',
            color: 'light-primary',
            title: user.order_approved,
            subtitle: 'Aprobadas',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'TrelloIcon',
            color: 'light-primary-second',
            title: user.order_in_warehouse,
            subtitle: 'En bodega',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'SlashIcon',
            color: 'light-secondary',
            title: user.order_detainees,
            subtitle: 'Detenidas',
            customClass: '',
          },
          {
            icon: 'TruckIcon',
            color: 'light-warning',
            title: user.order_ready_for_delivery,
            subtitle: 'Listas para entrega',
            customClass: '',
          },
          {
            icon: 'LayersIcon',
            color: 'light-info',
            title: user.order_partial_delivery,
            subtitle: 'Entrega parcial',
            customClass: '',
          },
          {
            icon: 'ClockIcon',
            color: 'light-danger',
            title: user.order_in_delivery,
            subtitle: 'En suministro',
            customClass: '',
          },
          {
            icon: 'ArchiveIcon',
            color: 'light-primary',
            title: user.order_complete_delivery,
            subtitle: 'Entrega completa',
            customClass: '',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            title: user.order_completed,
            subtitle: 'Completadas',
            customClass: '',
          },
          {
            icon: 'ClockIcon',
            color: 'light-warning',
            title: user.order_pending_payment,
            subtitle: 'Pendientes de pago',
            customClass: '',
          },
          {
            icon: 'SlashIcon',
            color: 'light-secondary',
            title: user.order_cancelled,
            subtitle: 'Canceladas',
            customClass: '',
          },
          {
            icon: 'CheckIcon',
            color: 'light-success',
            title: user.order_settled_payment,
            subtitle: 'Pago liquidado',
            customClass: '',
          },
        ]
      }

      this.loadingDataSeller = false
      this.$swal.close()
    },
    async onDeleteSeller(item) {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La información y registros del mayorista serán eliminados!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()
        console.log('item', item)
        try {
          await this.deleteSeller(item.IdUser)
          this.showSwalFire('¡El mayorista ha sido eliminado correctamente!')
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', error.response.data.message, 'danger')
        }
      }
    },
    showSwalFire(message) {
      this.$swal.fire({
        title: 'Eliminado',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$router.push({ name: 'wholesalers-home' })
        }
      })
    },

    /**
    * Total de ventas registradas
    *
    * @summary Carga el total de ventas registradas en base a un rango de fechas
    * @param {Object} filter - Representa el rango de fechas
    * @exception Muestra una notificación de error en caso de que falle la llamada al servidor
    */
    async loadTotalSale(filter) {
      try {
        let response = null

        if (filter) {
          response = await this.loadTotalSaleByUser({ userId: this.idUser, dateParams: filter })
        } else {
          response = await this.loadTotalSaleByUser({ userId: this.idUser })
        }

        this.totalSales.amount = response.data.data.total_sales
      } catch (error) {
        this.showToast('Error en el rango de fecha', getError(error), 'danger')
        this.totalSales.amount = 0
      }
    },

    /**
    * Total de pagos registrados
    *
    * @summary Carga el total de pagos registrados en base a un rango de fechas
    * @param {Object} filter - Representa el rango de fechas
    * @exception Muestra una notificación de error en caso de que falle la llamada al servidor
    */
    async loadTotalRegisteredPayments(filter) {
      try {
        let response = null

        if (filter) {
          response = await this.loadTotalRegisteredPaymentsByUser({ userId: this.idUser, dateParams: filter })
        } else {
          response = await this.loadTotalRegisteredPaymentsByUser({ userId: this.idUser })
        }

        this.totalPayments.amount = response.data.data.total_payment_recorded
      } catch (error) {
        this.showToast('Error en el rango de fecha', getError(error), 'danger')
        this.totalPayments.amount = 0
      }
    },

    /**
    * Mensaje de notificación
    *
    * @summary Despliega un mensaje de notificación
    * @param {string} title - Título del mensaje de notificación
    * @param {string} text - Contenido del mensaje de notificación
    * @param {string} variant - Tipo de notificación
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-autosuggest.scss';
.style-chooser {
  background: #ffffff;
}
</style>
